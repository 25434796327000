export const environment = {
  baseUrl: 'https://test.form.gwarancjeonline.pl',
  apiBase: 'https://test.gaponline.click/api',
  apkUrl: 'https://gwarancjeonline.pl/test',
  production: false,
  hmr: false,
  hotjar: '2094485',
  homeFtp: false,
  GTMID: 'GTM-MTK29BL',
  trustBadgeId: 'XB7B9D5A9A472D57D8E5D13B45673A7C4',
  SENTRY_MONITORING: true,
  SENTRY_AUTH_TOKEN:
    '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',
};
